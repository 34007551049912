import { createContext, useContext, useMemo } from 'react';

import type { Account, PurchasedFeatures, User } from '~/models';

export type UserProviderValue = {
  account: Account & { features: PurchasedFeatures; credits?: number };
  user: User;
};

const UserContext = createContext<UserProviderValue | Record<string, never>>(
  {},
);

type UserProviderProps = {
  value: UserProviderValue;
  children: React.ReactNode;
};

export const UserProvider = ({ value, children }: UserProviderProps) => {
  const userProviderValue = useMemo<UserProviderValue>(() => value, [value]);

  return (
    <UserContext.Provider value={userProviderValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
